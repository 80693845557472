/* eslint-disable */ // warning 표시 제거용

import React, { useEffect, useState, useRef, ReactElement } from 'react'; // useState 사용시 필수
import logo from './logo.svg';
import './App.css';
/*function App() {

  let [글제목,글제목변경] = useState('남자 코트 추천'); 
                                  // useState는 재렌더링이 필요하지 않음, 변수에 박아 넣지 않도록 할 것
  
  let posts='강남';

                                  //  addEventListener('clinck', ()=>), function()...
                                  //  onClick={()=>{}} //이벤트 발생
                                  //  console.log(1) //클릭마다 콘솔창에 1을 띄우게끔 만드는 함수

  let [따봉, 따봉변경] = useState(0);
                                  //  따봉의 경우, state가 수시로 변하기 때문에 useState 함수를 사용한다.
                                  //  state를 변경하고 싶을 경우, 따봉변경()을 이용한다.
                                  //  따봉변경(따봉 + 1); 를 쌩으로 넣을 경우, 무한 재렌더링이 걸리기 때문에
                                  // onClinck={}안에 따봉변경(따봉 + 1)을 넣어주어야 한다.


  /*  버튼으로 useState 상태를 변경하는 방법
  function 제목변경()
  {
    글제목변경(['여자 코트 추천']); // 글제목[0] = 1; //(x) state 직접 수정이 불가능하다.
  }                               // <--으로 함수를 선언, 제목변경()이라는 함수는 state를 변경하는 함수가 된다.
                                  //  이후 div 사이에 <button>을 추가해주면 된다.
                                  //  <button onClick={ 제목변경 }>버튼</button> */ 


  /*  deep copy 하는 방법
  function 제목변경()
  {
    //  var newArray = 글제목;     //  (x) 이건 복사가 아니라 값 공유이다. deep copy를 해서 수정해야 한다.
    var newArray = [...글제목];    //  (o) deep copy, 값 공유가 아니라, 서로 독립적인 값을 가지는 복사이다.
    newArray[0] = '여자 코트 추천';
    글제목변경( newArray );
                                  //  이 방식은 정렬(ex. 가나다 순 정렬)에도 응용할 수 있다.
  } */

  /*return (
    <div className="App">
      <div className="nav">
        <div > 개발 test </div>
      </div>
      <div className="list">
        <h3> {글제목} <span onClick={()=>{ 따봉변경(따봉 + 1) }}>🤞</span> { 따봉 } </h3>
        <p>2월 17일 발행</p>
        <hr/>
      </div>
      <div className="list">
        <h3> {posts} </h3>
        <p>2월 17일 발행</p>
        <hr/>
      </div>
      <div className="list">
        <h3> {posts} </h3>
        <p>2월 17일 발행</p>
        <hr/>
      </div>
      <div className="modal">
        <h2>제목</h2>
        <p>날짜</p>
        <p>상세내용</p>
      </div>
    </div>
    );
}
                                  //  return()에서, 소괄호 안에는 <div>가 평행하게 2개 이상 존재할 수 없다.
                                  //  다만, return에서 <div>를 평행하게 2개 이상 쓰고 싶다면 <></>를 이용한다.
                                  //  HTML을 한 단어로 줄여서 쓸 수 있는 방법 : 리액트의 Component 문법
/* function Modal()
{
  return
  (
    <div></div>                   //  <--Component 문법, HTML을 function에 넣고 <Modal></Modal>의 형식으로
  )                               //  사용할 수 있다.
} */
/*export default App;
*/

/*function event()
{
  const start_box = document.getElementById('start_box');

    if(start_box.style.display !== 'none')
    {
      start_box.style.display = 'none';
    }
    else
    {
      start_box.style.display = 'block';
    }
}*/

/*
function app()
{
  
  const [beforeStart,afterStart] = useState(true);
  const [yesToggle, yesToggleNum] = useState(0);
  const [noToggle,noToggleNum] = useState(0);



  return(
    
    <div className="bimg">
      <div className="gradation_top_to_bottom">
        <div className={`box ${beforeStart ? '' : 'survey_box'}`}>
          <div>
            {
            (function()
            {
              if(yesToggle == 0) return (<div id="a" className={`logo ${beforeStart ? '' : 'del_logo'}`}></div>);
              if(yesToggle == 1) return (<div id="a" className={`del_logo ${beforeStart ? '' : 'yes1question'}`}></div>);
              if(yesToggle == 2 && noToggle == 0) return (<div id="a" className={`yes1question ${beforeStart ? '' : 'yes2question'}`}></div>);
              if(yesToggle == 3 && noToggle == 0) return (<div id="a" className={`yes2question ${beforeStart ? '' : 'yes3question'}`}></div>);
              if(yesToggle == 4 && noToggle == 0) return (<div id="a" className={`yes3question ${beforeStart ? '' : 'yes4question'}`}></div>);
              
            })()
            }
            {
            (function()
            {
              if(yesToggle == 4 && noToggle == 0) return (<button className={`start_box ${beforeStart ? '' : 'fin_start_box'}`}>
              <p className="text_start">처음으로</p>
            </button>);
            })()
            }
            {
            (function()
            {
              if(yesToggle == 4 && noToggle == 0) return (document.getElementById("yes").style.display='none');
            })()
            }
            {
            (function()
            {
              if(yesToggle == 4 && noToggle == 0) return (document.getElementById("no").style.display='none');
            })()
            }
            
            
          </div>

          <button id="yes" className={`um_yes ${beforeStart ? '' : 'yes'}`} onClick={()=>yesToggleNum(yesToggle + 1)}>
            <p className="text_start">네</p>
            </button>

          <button id="no" className={`um_no ${beforeStart ? '' : 'no'}`} onClick={()=>noToggleNum(noToggle + 1)}>
          <p className="text_start">아니오</p>
          </button>

          <button className={`start_box ${beforeStart ? '' : 'del_start_box'}`} onClick={()=>{afterStart(false)}}>
            <p className="text_start">START</p>
          </button>

        </div>
      </div>
    </div>
    
  )
  
}
*/

//export default app;
 let g1=0;
 let g11=0, g12=0, g13=0, g14=0, g17=0, g16=0, g15=0, g18=0, g19=0;
 let g21=0, g23=0, g22=0, g25=0, g24=0, g26=0, g27=0, g28=0, g29=0;
 let g31=0, g32=0, g36=0, g33=0, g34=0, g35=0, g37=0, g38=0, g39=0;
 let g41=0, g42=0, g43=0, g44=0, g45=0, g46=0, g47=0, g48=0, g49=0;
 let g210=0, g211=0, g214=0, g212=0, g213=0, g215=0, g216=0, g218=0;
 let g310=0;
  let gfin=0;
  let langNum = 0;
  let warnBS = 0;
// 보규씨 코드
function app()
{
  const [no4Flag,No4Flag] = useState(true);
  const [beforeStart,afterStart] = useState(true);
  const [beforeStart2,afterStart2] = useState(true);
  const [yesToggle, yesToggleNum] = useState(0);
  const [noToggle,noToggleNum] = useState(0);
  const [warningYes,WarningYestoggle]=useState(true);

  const [ClickButton,ClickButtonNum] = useState(0);
  const [isShow, IsShow] = useState(true);
  const [isShow2, IsShow2] = useState(true);
  const [isShow3, IsShow3] = useState(true);
  const [G5StartNum, G5Start] = useState(true);
  const [language,languagech] = useState(true);



  React.useEffect(() => {
    if(gfin == 1) {
        IsShow(false);
    }
    else {
        IsShow(true);
    }
  }, [yesToggle, noToggle]);
  //
  React.useEffect(() => {
    if(G5Start >= 1) {
        IsShow2(false);
    } 
    else {
        IsShow2(true);
    }
  }, [yesToggle, noToggle]);

  let divElem = null;
  let divElem1 = null;
  let divElem2 = null;
  let divFinElem = null;
  let divLogoElem = null;
  let divFinElem2 = null;
  let divFinButtonElem = null;
  let div4NoFlagElem = null;
  let divFinElemE2 =null;
  let warn = null;
  let warnE = null;
  let warnE2z = null;
  let divWarningBeforeStart = null;
  let koreanQue = null;
  let englishQue = null;
  //
  let divG5_1 = null;

  const [g,gNum] = useState(0);

  if(ClickButton == 0)
  {
    divLogoElem = (
      <div className="logo"></div>
    )
  }

  else if(yesToggle == 0 && noToggle == 0 && ClickButton != 0 && warnBS == 1)
  {
    g21 = 1;
    g11 = 1;
    if(langNum == 0)
    {
    divElem = (
        <div id="a" className={`z1_questuon ${beforeStart ? '' : 'z1_question'}`}>
          <p className="ques1z"><span className="FcolorRed">치통</span>이 있습니까?</p>
        </div>
    );
    }
    else
    {
      divElem = (
        <div id="a" className={`z1_questuon ${beforeStart ? '' : 'z1_question'}`}>
          <p className="ques2zE">Do you have a <span className="FcolorRed">toothache</span>?</p>
        </div>
      )
    }
  }
  //n
  else if (yesToggle == 0 && noToggle == 1 && g11 == 1)
  {
    g12 = 1;
    g15 = 1;
    if(langNum == 0)
    {
    divElem = (
      <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
        <p className="ques2z"><span className="FcolorOrange">치아 변색</span>이 있거나</p>
        <p className="ques2z">잇몸이 <span className="FcolorRed">부었습니까</span>?</p>
      </div>
    );
    }
    else
    {
      divElem = (
      <div id="a" className={`z3_question ${beforeStart ? '' : 'z3_question'}`}>
        <p className="ques3zE">Do you have</p>
        <p className="ques3zE"><span className="FcolorOrange">discolored teeth</span></p>
        <p className="ques3zE">or <span className="FcolorRed">swollen gums</span>?</p>
      </div>
      );
    }

    }
    //ny
  if (yesToggle == 1 && noToggle == 1 && g12 == 1)
  {
    g14=1;
    g13=1;
    if(langNum == 0)
    {
    divElem = (
      <div id="a" className={`z1_question ${beforeStart ? '' : 'z1_question'}`}>
        <p className="ques1z">치아가 <span className="FcolorRed">흔들립니까</span>?</p>
      </div>
  );
    }
    else
    {
      divElem = (
        <div id="a" className={`z1_question ${beforeStart ? '' : 'z1_question'}`}>
          <p className="ques1zE">Are your <span className="FcolorRed">teeth shaking</span>?</p>
        </div>
    );
    }
  }
  //nyy_fin
  if (yesToggle == 2 && noToggle == 1 && g13 == 1)
  {
    gfin=1;
    if(langNum==0)
    {
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle"><span className="FcolorRRed">급성치주질환</span></p>)
    divElem = (
      <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>
        <p className="quesFin"><span className="FcolorRed">급성 치주질환</span>의</p>
        <p className="quesFin">가능성이 <span className="FcolorRed">있습니다</span>.</p>
        <p>&nbsp;</p>
        <p className="quesFin"><span className="FcolorRed">빨리</span> 치과에</p>
        <p className="quesFin">가셔야 합니다.</p>
      </div>
  );
    }
    else
    {
      warnE = (<p className="warnnE">These results are for reference only,</p>);
      warnE2z = (<p className="warnnE2Z">consult your dentist for a final diagnosis</p>);
      divFinElem = (<p className="quesFinTitle"><span className="FcolorRRed">Periodontal Disease</span></p>)
      divElem = (
        <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>
          <p className="quesFinE">There is a possibility of</p>
          <p className="quesFinE"><span className="FcolorRed">periodontal disease</span>.</p>
          <p>&nbsp;</p>
          <p className="quesFinE">You need to go to</p>
          <p className="quesFinE">the dentist <span className="FcolorRed">quickly</span>.</p>
        </div>
    );
    }
  }
  if(yesToggle == 2 && noToggle == 1 && g13 == 1 && gfin == 1)
  {
    divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
  }
  //nyn_fin
  if (yesToggle == 1 && noToggle == 2 && g14 == 1)
  {
    gfin=1;
    if(langNum==0)
    {
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
    divElem = (
      <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>
        <p className="quesFin">신경이 죽었을 가능성이</p>
        <p className="quesFin"><span className="FcolorRed">높은 상태</span>입니다.</p>
        <p>&nbsp;</p>
        <p className="quesFin">응급하지는 않지만</p>
        <p className="quesFin">치과에 <span className="FcolorRed">가셔야 합니다</span>.</p>
      </div>
  );
    }
    else
    {
      warnE = (<p className="warnnE">These results are for reference only,</p>);
      warnE2z = (<p className="warnnE2Z">consult your dentist for a final diagnosis</p>);
      divFinElem = (<p className="quesFinTitle_2_E">Condition of</p>)
      divFinElemE2 = (<p className="quesFinTitle_2_E_2Z">teeth and gums</p>)
      divFinElem2 =(<p className="quesFinTitle_2_2_E"><span className="FcolorOrange">A bit bad</span></p>)
      divElem = (
        <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>
          <p className="quesFinE">There is a possibility</p>
          <p className="quesFinE">that nerve is <span className="FcolorRed">dead</span>.</p>
          <p>&nbsp;</p>
          <p className="quesFinE">It's not a emergency,</p>
          <p className="quesFinE">but You need to go to the dentist.</p>
        </div>
    );
    }
  }
  if(yesToggle == 1 && noToggle == 2 && g14 == 1 && gfin == 1)
  {
    divElem1 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle-1); gfin=0;}}></button>)
  }

  //nn
  else if (yesToggle == 0 && noToggle == 2 && g15 == 1)
  {
    g17=1;
    g16=1;
    divElem = (
      <div id="a" className={`z3_question ${beforeStart ? '' : 'z3_question'}`}>
        <p className="ques3z">떼운치아(수복한 치아)가</p>
        <p className="ques3z"><span className="FcolorRed">빠졌거나</span></p>
        <p className="ques3z">안 끼던 음식물이 <span className="FcolorBlue">끼나요</span>?</p>
      </div>
  );
  }
  //nny_fin
  else if (yesToggle == 1 && noToggle == 2 && g16 == 1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorBlue">보통</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
      <p className="quesFinSmall">오래된 보철이 빠졌거나</p>
      <p className="quesFinSmall">치아의 일부가</p>
      <p className="quesFinSmall">깨졌을 가능성이 <span className="FcolorRed">있습니다</span>.</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall">응급하지는 않지만</p>
      <p className="quesFinSmall">치과에 <span className="FcolorRed">가셔야 합니다</span>.</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall">신경 치료의 가능성은 <span className="FcolorBlue">낮습니다</span>.</p>
    </div>
  );
  }
  if(yesToggle == 1 && noToggle == 2 && g16 == 1 && gfin == 1)
  {
    divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
  }
  //nnn
  else if (yesToggle == 0 && noToggle == 3 && g17 == 1)
  {
    g18=1;
    g19=1;
    divElem = (
      <div id="a" className={`z1_question ${beforeStart ? '' : 'z1_question'}`}>
        <p className="ques1z">치아가 <span className="FcolorRed">흔들립니까</span>?</p>
      </div>
    )}
  //nnny_fin
  else if (yesToggle == 1 && noToggle == 3 && g18 == 1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorBlue">보통</span></p>)
    divElem = (
      <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>        
      <p className="quesFin">치주질환 가능성이</p>
      <p className="quesFin"><span className="FcolorRed">있습니다</span>.</p>
      <p>&nbsp;</p>
      <p className="quesFin">치아신경이 죽었을 가능성은</p>
      <p className="quesFin"><span className="FcolorBlue">낮습니다</span>.</p>
    </div>
  );
    }
    if(yesToggle == 1 && noToggle == 3 && g18 == 1 && gfin == 1)
    {
      divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
    }
  //nnnn_fin
  else if (yesToggle == 0 && noToggle == 4 && g19 == 1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorGreen">정상</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
      <p className="quesFinSmall2">치아와 잇몸이</p>
      <p className="quesFinSmall2"><span className="FcolorGreen">정상</span>일 가능성이 있습니다.</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall2">정기적인 스케일링 하시고,</p>
      <p className="quesFinSmall2">파노라마 엑스레이를 이용한</p>
      <p className="quesFinSmall2">정기 검진을 <span className="FcolorBlue">추천</span>합니다.</p>
    </div>
  );
  }
  if(yesToggle == 0 && noToggle == 4 && g19 == 1 && gfin == 1)
  {
    divElem1 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle-1); gfin=0;}}></button>)
  }

  //y
  else if(yesToggle == 1 && noToggle == 0 && g21 == 1)
  {
    g22=1;
    g31=1;
    divElem = (
        <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
          <p className="ques2z"><span className="FcolorRed">통증</span>이 심하고</p>
          <p className="ques2z">오래 지속됩니까?</p>
        </div>
    );
    
  }
  //yn
  else if(yesToggle == 1 && noToggle == 1 && g22 == 1)
  {
    g23=1;
    g28=1;
    divElem = (
        <div id="a" className={`z3_question ${beforeStart ? '' : 'z3_question'}`}>
          <p className="ques3z">정수기<span className="FcolorBlue"> 찬물</span>을</p>
          <p className="ques3z">입에 머금고 있으면</p>
          <p className="ques3z">치아가 <span className="FcolorRed">아픕니까</span>?</p>
        </div>
    );
    
  }
  ///////yny
  else if(yesToggle == 2 && noToggle == 1 && g23 == 1)
  {
    g24=1;
    g25=1;
    divElem = (
      <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
        <p className="ques2z">양치질 할 때</p>
        <p className="ques2z">치아가 <span className="FcolorRed">시립니까</span>?</p>
      </div>
  );
}
  //ynyy_fin
  else if(yesToggle == 3 && noToggle == 1 && g24 == 1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
      <p className="quesFinSmall3">치경부 마모증이나</p>
      <p className="quesFinSmall3">초기 충치의 가능성이</p>
      <p className="quesFinSmall3"><span className="FcolorRed">있습니다</span>.</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall3">수복 치료가 <span className="FcolorBlue">추천</span>됩니다.</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall3">신경 치료의 필요성은</p>
      <p className="quesFinSmall3"><span className="FcolorBlue">낮습니다</span>.</p>
    </div>
  );
    }
    if(yesToggle == 3 && noToggle == 1 && g24 == 1 && gfin == 1)
    {
      divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
    }
  //ynyy_fin
  
  //ynyn
  else if(yesToggle == 2 && noToggle == 2 && g25 == 1)
  {
    g26=1;
    g27=1;
    divElem = (
      <div id="a" className={`z3_question ${beforeStart ? '' : 'z3_question'}`}>
        <p className="ques3z">초콜릿과 같이</p>
        <p className="ques3z">단 음식을 먹을 때</p>
        <p className="ques3z">치아가 <span className="FcolorRed">아픕니까</span>?</p>
      </div>
  );
}
  //ynyny_fin
  else if(yesToggle == 3 && noToggle == 2 && g26 ==1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
      <p className="quesFinSmall4">치아에 <span className="FcolorRed">금이 갔거나</span></p>
      <p className="quesFinSmall4">오래된 보철 주변으로</p>
      <p className="quesFinSmall4"><span className="FcolorRed">충치</span>가 생겼을 가능성이</p>
      <p className="quesFinSmall4"><span className="FcolorRed">있습니다</span>.</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall4">신속히 원인 치아를 찾아내면</p>
      <p className="quesFinSmall4">신경 치료의 필요성은 <span className="FcolorBlue">낮습니다</span>.</p>
    </div>
  );
    }
    if(yesToggle == 3 && noToggle == 2 && g26 == 1 && gfin == 1)
    {
      divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
    }
  //ynynn_fin
  else if(yesToggle == 2 && noToggle == 3 && g27 ==1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">신경치료 필요성</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorBlue">낮음</span></p>)
      divElem = (
        <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
        <p className="quesFinSmall5">초기 <span className="FcolorRed">충치</span>이거나 치아가</p>
        <p className="quesFinSmall5">일부 깨졌을 가능성이 <span className="FcolorRed">있습니다</span>.</p>
      </div>
      );
  }
  if(yesToggle == 2 && noToggle == 3 && g27 == 1 && gfin == 1)
  {
    divElem1 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle-1); gfin=0;}}></button>)
  }
  //ynn
  else if(yesToggle == 1 && noToggle == 2 && g28 == 1)
  {
    g29=1;
    g210=1;
    divElem = (
      <div id="a" className={`z3_question ${beforeStart ? '' : 'z3_question'}`}>
        <p className="ques3z"><span className="FcolorRed">뜨거운 물</span>을</p>
        <p className="ques3z">입에 머금고 있으면</p>
        <p className="ques3z">치아가 <span className="FcolorRed">아픕니까</span>?</p>
      </div>
  );
}
  //ynny_fin
  else if(yesToggle == 2 && noToggle == 2 && g29 == 1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorRRed">나쁨</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
      <p className="quesFinSmall6">신경 치료를 받은 치아라면</p>
      <p className="quesFinSmall6"><span className="FcolorRed">재신경 치료</span>를 받아야 하고,</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall6">그렇지 않은 치아라면</p>
      <p className="quesFinSmall6"><span className="FcolorRed">충치</span>가 심각할 가능성이</p>
      <p className="quesFinSmall6"><span className="FcolorRed">있습니다</span>.</p>
    </div>
  );
    }
    if(yesToggle == 2 && noToggle == 2 && g29 == 1 && gfin == 1)
    {
      divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
    }
  //ynnn
  else if(yesToggle == 1 && noToggle == 3 && g210 == 1)
  {
    g211=1;
    g214=1;
    divElem = (
      <div id="a" className={`z1_question ${beforeStart ? '' : 'z1_question'}`}>
        <p className="ques1z">씹을 때 <span className="FcolorRed">아픕니까</span>?</p>
      </div>
    )}
  //ynnny
  else if(yesToggle == 2 && noToggle == 3 && g211 == 1)
  {
    g212=1;
    g213=1;
    divElem = (
      <div id="a" className={`z1_question ${beforeStart ? '' : 'z1_question'}`}>
        <p className="ques1z">씹을 때마다 <span className="FcolorRed">아픕니까</span>?</p>
      </div>
    )}
  //ynnnyy_fin
  else if(yesToggle == 3 && noToggle == 3 && g213 == 1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
      <p className="quesFinSmall4">치아에 <span className="FcolorRed">금이 갔거나</span></p>
      <p className="quesFinSmall4">오래된 보철 주변으로</p>
      <p className="quesFinSmall4"><span className="FcolorRed">충치</span>가 생겼을 가능성이</p>
      <p className="quesFinSmall4"><span className="FcolorRed">있습니다</span>.</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall4">신속히 원인 치아를 찾아내면</p>
      <p className="quesFinSmall4">신경 치료의 필요성은 <span className="FcolorBlue">낮습니다</span>.</p>
    </div>
  );
    }
    if(yesToggle == 3 && noToggle == 3 && g213 == 1 && gfin == 1)
    {
      divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
    }
  //ynnnyn_fin
  else if(yesToggle == 2 && noToggle == 4 && g212 == 1)
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
    divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
      <p className="quesFinSmall6">치아에 금이 갔을 가능성이</p>
      <p className="quesFinSmall6"><span className="FcolorRed">있습니다</span>.</p>
      <p>&nbsp;</p>
      <p className="quesFinSmall6">신속히 원인 치아를 찾아야 하고</p>
      <p className="quesFinSmall6">필요하다면 <span className="FcolorRed">신경치료를</span></p>
      <p className="quesFinSmall6"><span className="FcolorRed">받아야 합니다</span>.</p>
    </div>
  );
    }
    if(yesToggle == 2 && noToggle == 4 && g212 == 1 && gfin == 1)
    {
      divElem1 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle-1); gfin=0;}}></button>)
    }
  ///
    //ynnnn
    else if(yesToggle == 1 && noToggle == 4 && g214 == 1)
    {
      g215=1;
      g216=1;
      divElem = (
        <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
          <p className="ques2z">양치질 할 때</p>
          <p className="ques2z">치아가 <span className="FcolorRed">시립니까</span>?</p>
        </div>
    );
  }
    //ynnnny_fin
    else if(yesToggle == 2 && noToggle == 4 && g215 == 1)
    {
      gfin=1;
      warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
      divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
      divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
      divElem = (
        <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
        <p className="quesFinSmall6">치경부 마모증의 가능성이</p>
        <p className="quesFinSmall6"><span className="FcolorRed">있습니다</span>.</p>
        <p>&nbsp;</p>
        <p className="quesFinSmall6">치경부 수복 치료가 <span className="FcolorBlue">추천</span>됩니다.</p>
        <p>&nbsp;</p>
        <p className="quesFinSmall6">신경치료의 필요성은 <span className="FcolorBlue">낮습니다</span>.</p>
      </div>
    );
      }
      if(yesToggle == 2 && noToggle == 4 && g215 == 1 && gfin == 1)
      {
        divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
      }
    //ynnnnn
    else if(yesToggle == 1 && noToggle == 5 && g216 == 1)
    {
      g218=1;
      g213=1;
      divElem = (
        <div id="a" className={`z3_question ${beforeStart ? '' : 'z3_question'}`}>
          <p className="ques3z">초콜릿과 같이</p>
          <p className="ques3z">단 음식을 먹을 때</p>
          <p className="ques3z">치아가 <span className="FcolorRed">아픕니까</span>?</p>
        </div>
    );
  }
        //ynnnnnn_fin
        else if(yesToggle == 1 && noToggle == 6 && g218 == 1)
        {
          gfin=1;
          warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
          divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
          divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
          divElem = (
            <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
            <p className="quesFinSmall6">신경치료를 받은 치아의 문제거나</p>
            <p className="quesFinSmall6">치주 질환의 가능성이 <span className="FcolorRed">있습니다</span>.</p>
            <p>&nbsp;</p>
            <p className="quesFinSmall6">신경치료를 받은 치아라면</p>
            <p className="quesFinSmall6">재신경 치료나 치근단 수술,</p>
            <p className="quesFinSmall6">또는 치아 재식술이 <span className="FcolorRed">필요합니다</span>.</p>
          </div>
        );
          }
          if(yesToggle == 1 && noToggle == 6 && g218 == 1 && gfin == 1)
          {
            divElem1 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle-1); gfin=0;}}></button>)
          }
    
        //ynnnnny
        else if(yesToggle == 2 && noToggle == 5 && g213 == 1)
        {
          gfin=1;
          warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
          divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
          divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
          divElem = (
            <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
            <p className="quesFinSmall4">치아에 <span className="FcolorRed">금이 갔거나</span></p>
            <p className="quesFinSmall4">오래된 보철 주변으로</p>
            <p className="quesFinSmall4"><span className="FcolorRed">충치</span>가 생겼을 가능성이</p>
            <p className="quesFinSmall4"><span className="FcolorRed">있습니다</span>.</p>
            <p>&nbsp;</p>
            <p className="quesFinSmall4">신속히 원인 치아를 찾아내면</p>
            <p className="quesFinSmall4">신경 치료의 필요성은 <span className="FcolorBlue">낮습니다</span>.</p>
          </div>
        );
          }
          if(yesToggle == 2 && noToggle == 5 && g213 == 1 && gfin == 1)
          {
            divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
          }


  //yy
  else if(yesToggle == 2 && noToggle == 0 && g31 == 1) 
  {
    g32=1;
    g41=1;
    divElem = (
      <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
        <p className="ques2z">가만히 있어도</p>
        <p className="ques2z">치아가 <span className="FcolorRed">아픕니까</span>?</p>
      </div>
  );
    }
  //yyn
  else if(yesToggle == 2 && noToggle == 1 && g32 == 1) 
  {
    g36=1;
    g33=1;
    divElem = (
      <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
        <p className="ques2z">찬물에 닿으면 통증이</p>
        <p className="ques2z"><span className="FcolorRed">심하게, 오래 갑니까</span>?</p>
      </div>
  );
    }
    //yyny
    else if(yesToggle == 3 && noToggle == 1 && g33 == 1) 
    {
      g34=1;
      g35=1;
      divElem = (
        <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
          <p className="ques2z">씹으면 치아가</p>
          <p className="ques2z">더 <span className="FcolorRed">아픕니까</span>?</p>
        </div>
    );
      }
            //yynyn_fin
            else if(yesToggle == 3 && noToggle == 2 && g34 == 1) 
            {
              gfin=1;
              warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
              divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
              divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorRRed">나쁨</span></p>)
              divElem = (
                <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
                <p className="quesFinSmall6"><span className="FcolorRed">치아 파절</span>의 가능성이</p>
                <p className="quesFinSmall6"><span className="FcolorRed">있습니다</span>.</p>
                <p>&nbsp;</p>
                <p className="quesFinSmall6">빨리 신경 치료를 해야 하며,</p>
                <p className="quesFinSmall6">심한 경우 <span className="FcolorRed">치아를 발치</span>해야</p>
                <p className="quesFinSmall6">할 수도 있습니다.</p>
              </div>
            );
              }
              if(yesToggle == 3 && noToggle == 2 && g34 == 1 && gfin == 1)
              {
                divElem1 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle-1); gfin=0;}}></button>)
              }
        //yynyy_fin
        else if(yesToggle == 4 && noToggle == 1 && g35 == 1) 
        {
          gfin=1;
          warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
              divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
              divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorRRed">나쁨</span></p>)
              divElem = (
                <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
                <p className="quesFinSmall7"><span className="FcolorRed">심한 충치</span>이거나 <span className="FcolorRed">치아 파절</span>로</p>
                <p className="quesFinSmall7">신경이 노출되었을 가능성이</p>
                <p className="quesFinSmall7"><span className="FcolorRed">있습니다</span>.</p>
                <p>&nbsp;</p>
                <p className="quesFinSmall7">신경치료 필요성이 <span className="FcolorRed">있습니다</span>.</p>
              </div>
            );
              }
              if(yesToggle == 4 && noToggle == 1 && g35 == 1 && gfin == 1)
              {
                divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
              }
    //yynn
    else if(yesToggle == 2 && noToggle == 2 && g36 == 1) 
    {
      g38=1;
      g37=1;
      divElem = (
        <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
          <p className="ques2z"><span className="FcolorRed">뜨거운 물</span>에 닿으면</p>
          <p className="ques2z">통증이 <span className="FcolorRed">오래 갑니까</span>?</p>
        </div>
    );
  }
    //yynny
    else if(yesToggle == 3 && noToggle == 2 && g37 == 1) 
    {
      gfin=1;
      warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle"><span className="FcolorRRed">급성치수염(말기)</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>
         <p className="quesFinSmall7">여러 신경 및 혈관이 분포해 있는</p>
        <p className="quesFinSmall7">치아 내부의 치수에 <span className="FcolorRed">급성 염증</span>이</p>
        <p className="quesFinSmall7">생기는 <span className="FcolorRed">질환</span>으로,</p>
        <p>&nbsp;</p>
        <p className="quesFinSmall7"><span className="FcolorRed">빨리</span> 신경 치료를 받으셔야 합니다.</p>
      </div>
  );
  }
  if(yesToggle == 3 && noToggle == 2 && g37 == 1 && gfin == 1)
  {
    divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
  }
  
    //yynnn
    else if(yesToggle == 2 && noToggle == 3 && g38 == 1) 
    {
      g39=1;
      g310=1;
      divElem = (
        <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
          <p className="ques2z"><span className="FcolorOrange">비염</span>이나</p>
          <p className="ques2z"><span className="FcolorOrange">축농증</span>이 있습니까?</p>
        </div>
    );
  }
        //yynnny_fin
        else if(yesToggle == 3 && noToggle == 3 && g39 == 1) 
        {
          gfin=1;
          warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
          divFinElem2 =(<p className="quesFinTitle"><span className="FcolorOrange">상악동염 / 부비동염</span></p>)
          divElem = (
            <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>        
            <p className="quesFin2"><span className="FcolorOrange">상악동염</span>이나 <span className="FcolorOrange">부비동염</span>의</p>
            <p className="quesFin2">가능성이 있습니다.</p>
            <p>&nbsp;</p>
            <p className="quesFin2"><span className="FcolorRed">이비인 후과</span>에 가셔야 합니다.</p>
          </div>
        );
          }
          if(yesToggle == 3 && noToggle == 3 && g39 == 1 && gfin == 1)
          {
            divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
          }
        //yynnnn_fin
        else if(yesToggle == 2 && noToggle == 4 && g310 == 1) 
        {
          gfin=1;
          warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
              divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
              divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorRRed">나쁨</span></p>)
              divElem = (
                <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
                <p className="quesFinSmall8"><span className="FcolorRed">급성 치주 농양</span>이거나</p>
                <p className="quesFinSmall8"><span className="FcolorRed">사랑니 주위염</span>일 가능성이 <span className="FcolorRed">있습니다</span>.</p>
                <p>&nbsp;</p>
                <p className="quesFinSmall8">신경치료 필요성은 <span className="FcolorBlue">낮습니다</span>.</p>
              </div>
            );
              }
              if(yesToggle == 2 && noToggle == 4 && g310 == 1 && gfin == 1)
              {
                divElem1 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle-1); gfin=0;}}></button>)
              }



  //yyy
  else if(yesToggle == 3 && noToggle == 0 && g41 == 1) 
  {
    g42=1;
    g43=1;//no
    divElem = (
      <div id="a" className={`z3_question ${beforeStart ? '' : 'z3_question'}`}>
        <p className="ques3z"><span className="FcolorBlue">찬 물</span> 혹은 <span className="FcolorRed">뜨거운 물</span>에</p>
        <p className="ques3z">닿을 때 치아의 <span className="FcolorRed">통증</span>이</p>
        <p className="ques3z"><span className="FcolorRed">심하게, 오래</span> 갑니까?</p>
      </div>
  );
    }
    //yyyn
    else if(yesToggle == 3 && noToggle == 1 && g43 == 1) 
    {
      g44=1;
      g45=1;
      divElem = (
        <div id="a" className={`z1_question ${beforeStart ? '' : 'z1_question'}`}>
          <p className="ques1z">치아가 <span className="FcolorRed">솟구친 느낌</span>입니까?</p>
        </div>
      )}
        //yyynn
        else if(yesToggle == 3 && noToggle == 2 && g45 == 1) 
        {
          g47=1;
          g46=1;
          divElem = (
            <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
              <p className="ques2z">잇몸이 <span className="FcolorRed">붓거나</span></p>
              <p className="ques2z">치아가 <span className="FcolorRed">흔들립니까</span>?</p>
            </div>
        );
      }
        //yyynnn
        else if(yesToggle == 3 && noToggle == 3 && g47 == 1) 
        {
          g48=1;
          g49=1;
          divElem = (
            <div id="a" className={`z2_question ${beforeStart ? '' : 'z2_question'}`}>
              <p className="ques2z"><span className="FcolorOrange">비염</span>이나</p>
              <p className="ques2z"><span className="FcolorOrange">축농증</span>이 있습니까?</p>
            </div>
        );
      }
        //yyynnny_fin
        else if(yesToggle == 4 && noToggle == 3 && g48 == 1) 
        {
          gfin=1;
          warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
          divFinElem2 =(<p className="quesFinTitle"><span className="FcolorOrange">상악동염 / 부비동염</span></p>)
          divElem = (
            <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>        
            <p className="quesFin2"><span className="FcolorOrange">상악동염</span>이나 <span className="FcolorOrange">부비동염</span>의</p>
            <p className="quesFin2">가능성이 있습니다.</p>
            <p>&nbsp;</p>
            <p className="quesFin2"><span className="FcolorRed">이비인 후과</span>에 가셔야 합니다.</p>
          </div>
        );
          }
          if(yesToggle == 4 && noToggle == 3 && g48 == 1 && gfin == 1)
          {
            divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
          }
        //yyynnnn_fin
        else if(yesToggle == 3 && noToggle == 4 && g49 == 1) 
        {
          gfin=1;
          warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
              divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
              divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorOrange">약간 나쁨</span></p>)
              divElem = (
                <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>        
                <p className="quesFin3">치아 뿌리의 <span className="FcolorRed">급염증</span>으로,</p>
                <p>&nbsp;</p>
                <p className="quesFin3">파노라마나 CT촬영을 통해</p>
                <p className="quesFin3"><span className="FcolorRed">정밀 검사</span>를 받아야 합니다.</p>
              </div>
            );
              }
              if(yesToggle == 3 && noToggle == 4 && g49 == 1 && gfin == 1)
              {
                divElem1 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle-1); gfin=0;}}></button>)
              }
        //yyynny_fin
        else if(yesToggle == 4 && noToggle == 2 && g46 == 1) 
        {
          gfin=1;
          warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
              divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
              divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorRRed">나쁨</span></p>)
              divElem = (
                <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>        
                <p className="quesFinSmall8"><span className="FcolorRed">급성 치주 농양</span>이거나</p>
                <p className="quesFinSmall8"><span className="FcolorRed">사랑니 주위염</span>일 가능성이 <span className="FcolorRed">있습니다</span>.</p>
                <p>&nbsp;</p>
                <p className="quesFinSmall8">신경치료 필요성은 <span className="FcolorBlue">낮습니다</span>.</p>
              </div>
            );
              }
              if(yesToggle == 4 && noToggle == 2 && g46 == 1 && gfin == 1)
              {
                divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
              }
    //yyyny_fin
    else if(yesToggle == 4 && noToggle == 1 && g44 == 1) 
    {
      gfin=1;
      warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
              divFinElem = (<p className="quesFinTitle_2">치아와 잇몸 상태</p>)
              divFinElem2 =(<p className="quesFinTitle_2_2"><span className="FcolorRRed">나쁨</span></p>)
              divElem = (
                <div id="a" className={`finBox ${beforeStart ? '' : 'finBox'}`}>        
                <p className="quesFin4">치아 뿌리의 <span className="FcolorRed">급성 염증</span>으로,</p>
                <p>&nbsp;</p>
                <p className="quesFin4">치근단 수술 또는</p>
                <p className="quesFin4">치아 재식술이 필요합니다.</p>
              </div>
            );
              }
              if(yesToggle == 4 && noToggle == 1 && g44 == 1 && gfin == 1)
              {
                divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
              }

    //yyyy
  else if(yesToggle == 4 && noToggle == 0 && g42 == 1) 
  {
    gfin=1;
    warn = (<p className="warnn">이 결과는 참고용이며, 최종진단은 치과의사와 상담하세요.</p>);
    divFinElem = (<p className="quesFinTitle"><span className="FcolorRRed">급성치수염</span></p>)
    divElem = (
      <div id="a" className={`finLBox ${beforeStart ? '' : 'finLBox'}`}>
         <p className="quesFinSmall7">여러 신경 및 혈관이 분포해 있는</p>
        <p className="quesFinSmall7">치아 내부의 치수에 <span className="FcolorRed">급성 염증</span>이</p>
        <p className="quesFinSmall7">생기는 <span className="FcolorRed">질환</span>으로,</p>
        <p>&nbsp;</p>
        <p className="quesFinSmall7"><span className="FcolorRed">빨리</span> 신경 치료를 받으셔야 합니다.</p>
      </div>
  );
  }
  if(yesToggle == 4 && noToggle == 0 && g42 == 1 && gfin == 1)
  {
    divElem1 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle-1); gfin=0;}}></button>)
  }

  /////////메인으로 돌아가는 버튼 뜨는 조건문////////
  if(gfin == 1) {
    if(langNum==0)
    {
    divFinButtonElem = (
        <button className="fin_start_box" onClick = {gomain}>
            <p className="text_start">처음으로</p>
        </button>
    )
    }
    else{
    divFinButtonElem = (
      <button className="fin_start_box" onClick = {gomain}>
          <p className="text_start">BACK</p>
      </button>
  )
    }
  }
  //
  //뒤로가기 노가다
  else if(yesToggle == 0 && noToggle == 0 && g1 == 1)
  {
    divElem1 = (<button className="backButton" onClick = {gomain}></button>)
  }
 else if(yesToggle >= 1 || noToggle >= 1)
  {
    //y
    if(yesToggle == 1 && noToggle == 0 && g21 == 1){
      divElem2 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle - 1); g22=0; g31=0}}></button>)
    }
    //n
    else if(noToggle == 1 && yesToggle == 0 && g11 == 1){
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g12=0; g15=0}}></button>)
    }

    //yy
    else if(yesToggle == 2 && noToggle == 0 && g31 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle - 1); g32=0; g41=0}}></button>)
    }
    //yyn
    else if(yesToggle == 2 && noToggle == 1 && g32 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g36=0; g33=0}}></button>)
    }
    //yyny
    else if(yesToggle == 3 && noToggle == 1 && g33 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle - 1); g34=0; g35=0}}></button>)
    }
    //yynn
    else if(yesToggle == 2 && noToggle == 2 && g36 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g38=0; g37=0}}></button>)
    }
    //yynnn
    else if(yesToggle == 2 && noToggle == 3 && g38 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g39=0; g310=0}}></button>)
    }

    //yyy
    else if(yesToggle == 3 && noToggle == 0 && g41 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle - 1); g42=0; g43=0}}></button>)
    }
    //yyyn
    else if(yesToggle == 3 && noToggle == 1 && g43 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g44=0; g45=0}}></button>)
    }
    //yyynn
    else if(yesToggle == 3 && noToggle == 2 && g45 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g47=0; g46=0}}></button>)
    }
    //yyynnn
    else if(yesToggle == 3 && noToggle == 3 && g47 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g48=0; g49=0}}></button>)
    }
    //yn
    else if(yesToggle == 1 && noToggle == 1 && g22 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g23=0; g28=0}}></button>)
    }
    //ny
    else if(yesToggle == 1 && noToggle == 1 && g12 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle - 1); g14=0; g13=0}}></button>)
    }
    //nn
    else if(yesToggle == 0 && noToggle == 2 && g15 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g17=0; g16=0}}></button>)
    }
    //nnn
    else if(yesToggle == 0 && noToggle == 3 && g17 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g18=0; g19=0}}></button>)
    }
    //yny
    else if(yesToggle == 2 && noToggle == 1 && g23 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle - 1); g24=0; g25=0}}></button>)
    }
    //ynyn
    else if(yesToggle == 2 && noToggle == 2 && g25 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g26=0; g27=0}}></button>)
    }
    //ynn
    else if(yesToggle == 1 && noToggle == 2 && g28 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g29=0; g210=0}}></button>)
    }
    //ynnn
    else if(yesToggle == 1 && noToggle == 3 && g210 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g211=0; g214=0}}></button>)
    }
    //ynnny
    else if(yesToggle == 2 && noToggle == 3 && g211 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{yesToggleNum(yesToggle - 1); g212=0; g213=0}}></button>)
    }
    //ynnnn
    else if(yesToggle == 1 && noToggle == 4 && g214 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g215=0; g216=0}}></button>)
    }
    //ynnnnn
    else if(yesToggle == 1 && noToggle == 5 && g216 == 1)
    {
      divElem2 = (<button className="backButton" onClick = {()=>{noToggleNum(noToggle - 1); g218=0; g213=0}}></button>)
    }
  }
//
//
//divElem1 = (<button className="backButton" onClick = {gomain}></button>)
//
// 어느 것에도 속하지 않을 때 설문
if(gfin==1)
{
  div4NoFlagElem = 
  (
    <button style={{display: isShow ? "none" : "block"}} className="G5Btn" onClick={()=>IsShow2(false)}>
      <p className="G5Text">스무고개에 한 가지도 해당되지 않나요?</p>
    </button>
  )
}
//클릭시 실행되는 함수(리스트)
const [slideList, SlideList] = useState(true);
const [slideList_2, SlideList_2] = useState(true);
const [slideList_3, SlideList_3] = useState(true);
const [slideList_4, SlideList_4] = useState(true);
const [toggleNum, ToggleNum] = useState(0);
const [toggleNum_2, ToggleNum_2] = useState(0);
const [toggleNum_3, ToggleNum_3] = useState(0);
const [toggleNum_4, ToggleNum_4] = useState(0);

let divSlide1 = null;
let divSlide2 = null;
let divSlide3 = null;
let divSlide4 = null;
let divlanguage = null;

function ToggleMenu()
{
  SlideList(slideList => !slideList);
}
function ToggleMenu_2()
{
  SlideList_2(slideList_2 => !slideList_2);
}
function ToggleMenu_3()
{
  SlideList_3(slideList_3 => !slideList_3);
}
function ToggleMenu_4()
{
  SlideList_4(slideList_4 => !slideList_4);
}
//한영전환
function languageMenu()
{
  languagech(language => !language);
  if(langNum == 0)
  {
    langNum = 1;
    
    localStorage.setItem("langNum", 1);
  }
  else
  {
    langNum = 0;
    localStorage.setItem("langNum", 0);
  }
}
function warnnBSZ()
{
  warnBS=1;
  IsShow3(true);
}
//
if(toggleNum >= 1)
{
  divSlide1 = (
  <button className={`G5-1_ques_box ${slideList ? 'G5-1_ques_box' : 'G5-1_ques_box_clicked'}`} onClick={()=>{ToggleMenu(); ToggleNum(toggleNum + 1)}}>
    <p className={`G5Text_1_ques ${slideList ? 'G5Text_1_ques' : 'G5Text_1_ques_clicked'}`}>치아가 매우 아프다가 지금은 괜찮아졌습니까?</p>
    <p className={`G5Text_1_ans ${slideList ? 'G5Text_1_ans_clicked' : 'G5Text_1_ans'}`}>급성 치수염을 지나</p>
    <p className={`G5Text_1_ans ${slideList ? 'G5Text_1_ans_clicked' : 'G5Text_1_ans'}`}>치아 신경이 죽었을 가능성이 있습니다.</p>
    <p className={`G5Text_1_ans ${slideList ? 'G5Text_1_ans_clicked' : 'G5Text_1_ans'}`}>방치하면 치조골 염증이 생길 수 있으므로</p>
    <p className={`G5Text_1_ans ${slideList ? 'G5Text_1_ans_clicked' : 'G5Text_1_ans'}`}>빨리 신경 치료를 받아야 합니다.</p>
  </button>
  )
}
if(toggleNum_2 >= 1)
{
  divSlide2 = (
    <button className={`G5-2_ques_box ${slideList_2 ? 'G5-2_ques_box' : 'G5-2_ques_box_clicked'}`} onClick={()=>{ToggleMenu_2(); ToggleNum_2(toggleNum_2 + 1)}}>
      <p className={`G5Text_2_ques ${slideList_2 ? 'G5Text_2_ques' : 'G5Text_1_ques_clicked'}`}>찬물과 뜨거운 물에는 괜찮은데,</p>
      <p className={`G5Text_2_ques ${slideList_2 ? 'G5Text_2_ques' : 'G5Text_1_ques_clicked'}`}>치아가 어디가 아픈지 모르겠습니까?</p>
      <p className={`G5Text_2_ans ${slideList_2 ? 'G5Text_1_ans_clicked' : 'G5Text_2_ans'}`}>귀 앞을 만지면서 입을 벌렸다 다물어 보세요.</p>
      <p className={`G5Text_2_ans ${slideList_2 ? 'G5Text_1_ans_clicked' : 'G5Text_2_ans'}`}>이 때 귀 앞이 아프면,</p>
      <p className={`G5Text_2_ans ${slideList_2 ? 'G5Text_1_ans_clicked' : 'G5Text_2_ans'}`}>턱관절 질환의 가능성이 있습니다.</p>
      <p className={`G5Text_2_ans ${slideList_2 ? 'G5Text_1_ans_clicked' : 'G5Text_2_ans'}`}>즉, 치아의 문제가 아닐 가능성이 높기 때문에</p>
      <p className={`G5Text_2_ans ${slideList_2 ? 'G5Text_1_ans_clicked' : 'G5Text_2_ans'}`}>정확한 진단이 나오기 전까지</p>
      <p className={`G5Text_2_ans ${slideList_2 ? 'G5Text_1_ans_clicked' : 'G5Text_2_ans'}`}>치아 치료는 신중히 하는 것이 좋습니다.</p>
    </button>
  )
}
if(toggleNum_3 >= 1)
{
  divSlide3 = (
    <button className={`G5-3_ques_box ${slideList_3 ? 'G5-3_ques_box' : 'G5-3_ques_box_clicked'}`} onClick={()=>{ToggleMenu_3(); ToggleNum_3(toggleNum_3 + 1)}}>
      <p className={`G5Text_3_ques ${slideList_3 ? 'G5Text_3_ques' : 'G5Text_1_ques_clicked'}`}>씹을 때 한번씩 아프지만 매번 그렇지는 않으며,</p>
      <p className={`G5Text_3_ques ${slideList_3 ? 'G5Text_3_ques' : 'G5Text_1_ques_clicked'}`}>찬물과 뜨거운 물에는 아프지 않습니까?</p>
      <p className={`G5Text_3_ans ${slideList_3 ? 'G5Text_1_ans_clicked' : 'G5Text_3_ans'}`}>치아에 금이 갔을 가능성이 있지만</p>
      <p className={`G5Text_3_ans ${slideList_3 ? 'G5Text_1_ans_clicked' : 'G5Text_3_ans'}`}>단단한 음식을 피하면</p>
      <p className={`G5Text_3_ans ${slideList_3 ? 'G5Text_1_ans_clicked' : 'G5Text_3_ans'}`}>저절로 좋아질 가능성이 있습니다.</p>
    </button>
  )
}
if(toggleNum_4 >= 1)
{
  divSlide4 = (
    <button className={`G5-4_ques_box ${slideList_4 ? 'G5-4_ques_box' : 'G5-4_ques_box_clicked'}`} onClick={()=>{ToggleMenu_4(); ToggleNum_4(toggleNum_4 + 1)}}>
      <p className={`G5Text_4_ques ${slideList_4 ? 'G5Text_4_ques' : 'G5Text_1_ques_clicked'}`}>눕거나 자세가 바뀌면 치아가 아픕니까?</p>
      <p className={`G5Text_4_ans ${slideList_4 ? 'G5Text_1_ans_clicked' : 'G5Text_4_ans'}`}>상악동염이나 부비동염의 가능성이 있습니다.</p>
      <p className={`G5Text_4_ans ${slideList_4 ? 'G5Text_1_ans_clicked' : 'G5Text_4_ans'}`}>이비인후과에 가셔야 합니다.</p>
    </button>
  )
}
if(langNum == 0)
{
  divlanguage = (
    <p className="koreanButtonIcon">한글</p>
  )
}
if(langNum == 1)
{
  divlanguage = (
    <p className="englishButtonIcon">english</p>
  )
}
//gomain 함수
function gomain()
{
  g1=0;
  g11=0, g12=0, g13=0, g14=0, g17=0, g16=0, g15=0, g18=0, g19=0;
  g21=0, g23=0, g22=0, g25=0, g24=0, g26=0, g27=0, g28=0, g29=0;
  g31=0, g32=0, g36=0, g33=0, g34=0, g35=0, g37=0, g38=0, g39=0;
  g41=0, g42=0, g43=0, g44=0, g45=0, g46=0, g47=0, g48=0, g49=0;
  g210=0, g211=0, g214=0, g212=0, g213=0, g215=0, g216=0, g218=0;
  g310=0;
  gfin=0;
  afterStart(true);
  ClickButtonNum(0);
  yesToggleNum(0);
  noToggleNum(0);
  ToggleNum(0);
  ToggleNum_2(0);
  ToggleNum_3(0);
  ToggleNum_4(0);
  SlideList(true);
  SlideList_2(true);
  SlideList_3(true);
  SlideList_4(true);
  IsShow(true);
  IsShow2(true);
  IsShow3(true);
  WarningYestoggle(true);
  afterStart2(true);
  warnBS=0;
}
function gomain2()
{
  g1=0;
  g11=0, g12=0, g13=0, g14=0, g17=0, g16=0, g15=0, g18=0, g19=0;
  g21=0, g23=0, g22=0, g25=0, g24=0, g26=0, g27=0, g28=0, g29=0;
  g31=0, g32=0, g36=0, g33=0, g34=0, g35=0, g37=0, g38=0, g39=0;
  g41=0, g42=0, g43=0, g44=0, g45=0, g46=0, g47=0, g48=0, g49=0;
  g210=0, g211=0, g214=0, g212=0, g213=0, g215=0, g216=0, g218=0;
  g310=0;
  gfin=0;
  afterStart(true);
  ClickButtonNum(0);
  yesToggleNum(0);
  noToggleNum(0);
  ToggleNum(0);
  ToggleNum_2(0);
  ToggleNum_3(0);
  ToggleNum_4(0);
  SlideList(true);
  SlideList_2(true);
  SlideList_3(true);
  SlideList_4(true);
  IsShow(true);
  IsShow2(true);
  //<div style={{display: beforeStart ? "block" : "none"}} className='choi_logo'></div>
  //<div style={{display: beforeStart ? "block" : "none"}} className='choi_logo2'></div>
  //<p style={{display: beforeStart ? "none" : "none"}} className="PLAZENLOGO">판교최용훈치과</p>
  //<p style={{display: beforeStart ? "none" : "none"}} className="PLAZENLOGO2">PLAZEN RCT</p>
}
//
//const [warningYes,WarningYestoggle]=useState(0);
  return(
    
    <div className="bimg">
      <div className="gradation_top_to_bottom">
        
        <div style={{display: isShow2 ? "none" : "block"}} className="G5Box">
          <button className="G5BackButton" onClick={gomain}></button>
          <button className={`G5-1_ques_box ${slideList ? 'G5-1_ques_box' : 'G5-1_ques_box_clicked'}`} onClick={()=>{ToggleMenu(); ToggleNum(toggleNum + 1)}}>
          <p className={`G5Text_1_ques ${slideList ? 'G5Text_1_ques' : 'G5Text_1_ques_clicked'}`}>치아가 매우 아프다가 지금은 괜찮아졌습니까?</p>
          </button>
          {divSlide1}
          <button className={`G5-2_ques_box ${slideList_2 ? 'G5-2_ques_box' : 'G5-2_ques_box_clicked'}`} onClick={()=>{ToggleMenu_2(); ToggleNum_2(toggleNum_2 + 1)}}>
            <p className={`G5Text_2_ques ${slideList_2 ? 'G5Text_2_ques' : 'G5Text_1_ques_clicked'}`}>찬물과 뜨거운 물에는 괜찮은데,</p>
            <p className={`G5Text_2_ques ${slideList_2 ? 'G5Text_2_ques' : 'G5Text_1_ques_clicked'}`}>치아가 어디가 아픈지 모르겠습니까?</p>
          </button>
          {divSlide2}
          <button className={`G5-3_ques_box ${slideList_3 ? 'G5-3_ques_box' : 'G5-3_ques_box_clicked'}`} onClick={()=>{ToggleMenu_3(); ToggleNum_3(toggleNum_3 + 1)}}>
          <p className={`G5Text_3_ques ${slideList_3 ? 'G5Text_3_ques' : 'G5Text_1_ques_clicked'}`}>씹을 때 한번씩 아프지만 매번 그렇지는 않으며,</p>
          <p className={`G5Text_3_ques ${slideList_3 ? 'G5Text_3_ques' : 'G5Text_1_ques_clicked'}`}>찬물과 뜨거운 물에는 아프지 않습니까?</p>
          </button>
          {divSlide3}
          <button className={`G5-4_ques_box ${slideList_4 ? 'G5-4_ques_box' : 'G5-4_ques_box_clicked'}`} onClick={()=>{ToggleMenu_4(); ToggleNum_4(toggleNum_4 + 1)}}>
            <p className={`G5Text_4_ques ${slideList_4 ? 'G5Text_4_ans' : 'G5Text_1_ques_clicked'}`}>눕거나 자세가 바뀌면 치아가 아픕니까?</p>
          </button>
          {divSlide4}
        </div>
        <div  className={`box ${beforeStart ? 'box' : 'survey_box'}`}>
          <div style={{display: isShow3 ? "none" : "block"}} className='z11_question'>
    <p style={{display: isShow3 ? "none" : "block"}}  className="warningText">이 앱은 <span className="FcolorRRed">단순 참고용</span>이며,</p>
    <p style={{display: isShow3 ? "none" : "block"}} className="warningText">정확한 진단은 치과의사에게</p>
    <p style={{display: isShow3 ? "none" : "block"}} className="warningText">받아야 합니다.</p>
          <p>&nbsp;</p>
    <p style={{display: isShow3 ? "none" : "block"}} className="warningText">앱 진단 결과와</p>
    <p style={{display: isShow3 ? "none" : "block"}} className="warningText">치과진단 결과가 달라도</p>
    <p style={{display: isShow3 ? "none" : "block"}} className="warningText"><span className="FcolorRRed">문제 삼지 않고</span> 이 앱을</p>
    <p style={{display: isShow3 ? "none" : "block"}} className="warningText">시작하시겠습니까?</p>
      </div>
    <button style={{display: isShow3 ? "none" : "block"}} className='WarnYes'onClick={()=>{afterStart2(false);warnnBSZ(); g1=1;}}>
        <p className="text_start">
         네
        </p>
    </button>
      <button style={{display: isShow3 ? "none" : "block"}} className='WarnNo'onClick={()=>{gomain()}}>
       <p  className="text_start">
         아니오
        </p>
      </button>
          <div>
            <button style={{display: ClickButton ? "none" : "none"}} className={`korean ${language ? 'korean' : 'english'}`} onClick={()=>{languageMenu()}}>
              {divlanguage}
            </button>
            {divFinElemE2}
            {warn}
            {warnE}
            {warnE2z}
            {divFinElem}
            {divElem}
            {divElem1}
            {divElem2}
            {divLogoElem}
            {divFinButtonElem}
            {divFinElem2}
            {div4NoFlagElem}
          </div>
            <button style={{display: isShow ? "block" : "none"}} 
            className={`um_yes ${beforeStart2 ? 'um_no' : 'yes'}`} onClick={()=>{yesToggleNum(yesToggle + 1); ClickButtonNum(ClickButton + 1)}}>
            <p className="text_start">네</p>
            </button>

          <button style={{display: isShow ? "block" : "none"}}
          className={`um_no ${beforeStart2 ? 'um_no' : 'no'}`} onClick={()=>{noToggleNum(noToggle + 1); ClickButtonNum(ClickButton + 1)}}>
          <p className="text_start">아니오</p>
          </button>

          <button className={`start_box ${beforeStart ? 'start_box' : 'del_start_box'}`} onClick={()=>{{afterStart(false); ClickButtonNum(ClickButton + 1)}; IsShow3(false)}}>
            <p className="text_start">START</p>
          </button>
          <div className='bottom_logo_space'>
          </div>
        </div>
      </div>
    </div>
    
  )
  
}


export default app;
